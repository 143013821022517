import BS from "bootstrap/dist/js/bootstrap.bundle.min.js";

const MY_MODAL_ID = "my-modal-alert";

const buildModal = (message, title = "") => {
    if (document.getElementById(MY_MODAL_ID)) {
        document.getElementById(MY_MODAL_ID).remove();
    }
    const template = document.createElement('div');
    let header = title.length > 0
        ?
        `<div class="modal-header">
        <h5 class="modal-title">${title}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>`
        : "";
    template.innerHTML = `<div class="modal fade" id="my-modal-alert" tabIndex="-1" aria-labelledby="myModalAlert" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
      ${header}
        <div class="modal-body">
          <p>${message}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Ok</button>
        </div>
      </div>
    </div>
  </div>`;

    return new BS.Modal(template.firstChild, {});
}

export default buildModal