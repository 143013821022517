import React, { useEffect, useState } from "react";
import './InstallPWA.css';

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      console.log("InstallPWA useEffect");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = evt => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return null;
  }
  return (
    <div className="alert alert-success alert-dismissible" role="alert"
      style={{ width: "100%", position: "fixed", top: 0, zIndex: 99999 }}
    >
      <div>
        <button
          type="button"
          aria-label="Install app"
          title="Install app"
          className="btn btn-sm btn-outline-primary add-button btn-install"
          onClick={onClick}
        >
          <i className="fa-solid fa-download"></i> Install
        </button>
      </div>
      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
  );
};

export default InstallPWA;