import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Realm from "realm-web";
import { ContextProvider } from './context/Auth.context.js';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import './index.css';
import App from './App';
import InstallPWA from './components/InstallPWA';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const APP_ID = 'application-0-wdlna';
const app = new Realm.App({ id: APP_ID });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <InstallPWA />
    <ContextProvider myApp={app} >
      <App />
    </ContextProvider>
  </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(); //unregister(); //register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
