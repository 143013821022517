import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../context/Auth.context.js';
import EggListItem from './EggListItem.js';
import Autocomplete from './Autocomplete.js';
import ContactList from './ContactList.js';
import './Nest.css'

function Nest() {
    const { state: ContextState } = useContext(AuthContext);

    useEffect(() => {
        console.log(`Nest useEffect`);
    });

    //rxEggs
    const rxEggs = ContextState.user.customData.received;
    const rxEggsList = rxEggs.map((egg) => {
        const insDate = new Date(parseInt(egg.insDate.$date.$numberLong)).toLocaleString();
        const expDate = new Date(parseInt(egg.expDate.$date.$numberLong)).toLocaleString();
        const expDateLong = parseInt(egg.expDate.$date.$numberLong);
        return (
            <EggListItem key={egg.id} id={egg.id} from={egg.sentBy} insDate={insDate} expDate={expDate} expDateLong={expDateLong} />
        )
    })

    //txEggs
    const txEggs = ContextState.user.customData.sent;
    const txEggsList = txEggs.map((egg) => {
        const insDate = new Date(parseInt(egg.insDate.$date.$numberLong)).toLocaleString();
        const expDate = new Date(parseInt(egg.expDate.$date.$numberLong)).toLocaleString();
        return (
            <EggListItem key={egg.id} id={egg.id} to={egg.to} insDate={insDate} expDate={expDate} />
        )
    })

    return (
        <div id="Nest">
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="rxeggs" role="tabpanel" aria-labelledby="rxeggs-tab">
                    <ul className="list-group list-group-flush">
                        {rxEggsList}
                    </ul>
                </div>
                <div className="tab-pane fade" id="txeggs" role="tabpanel" aria-labelledby="txeggs-tab">
                    <ul className="list-group list-group-flush">
                        {txEggsList}
                    </ul>
                </div>
                <div className="tab-pane fade" id="contacts" role="tabpanel" aria-labelledby="contacts-tab">
                    <Autocomplete />
                    <ContactList contacts={ContextState.user.customData.contacts} />
                </div>
            </div>
        </div>
    )
}

export default Nest