function NewEggBtn({ className, title, username }) {

    return (
        <button type="button" title={title} id="layEgg-btn" className={className} data-bs-toggle="modal"
            data-bs-target="#layEggModal" data-bs-username={username}>
            <span role="img" aria-label="egg">🥚</span>
        </button>
    )
}

export default NewEggBtn 