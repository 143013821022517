import MenuElement from './MenuElement';

function MenuBtn({ logOut }) {
        return (
            <>
                <button id="menu-btn" className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav">
                        <MenuElement text="Account" modal="#accountModal" />
                        <MenuElement text="Logout" action={logOut} />
                    </div>
                </div>
            </>
        )
}

export { MenuBtn } 