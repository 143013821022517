import { useContext } from 'react'
import { AuthContext } from '../context/Auth.context.js';
import { MenuBtn } from './MenuBtn';
import './Header.css';

function Header({ loggedIn }) {
    const { state, logout } = useContext(AuthContext);

    return (
        <nav className="navbar navbar-light bg-gradient mb-1">
            <div className="container-fluid">
                <a className="navbar-brand" href="#!">
                    <img width="64" alt="logo"
                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDI1NiAyNTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgZmlsbD0ibm9uZSIgaGVpZ2h0PSIyNTYiIHdpZHRoPSIyNTYiLz48cGF0aCBkPSJNMjA4LDE1MmE4MCw4MCwwLDAsMS0xNjAsMEM0OCw4OCw5NiwyNCwxMjgsMjRTMjA4LDg4LDIwOCwxNTJaIiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI4Ii8+PHBvbHlsaW5lIGZpbGw9Im5vbmUiIHBvaW50cz0iMTUxLjQgMTUwIDE1OC42IDExMi45IDEyOS44IDEwMS43IDE3Mi43IDUzLjkiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjgiLz48L3N2Zz4=" />
                    EggMex
                </a>
                { state.isLoggedIn && (
                    <MenuBtn logOut={logout} />
                )}
            </div>
        </nav>
    )
}

export default Header