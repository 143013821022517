import { useEffect } from 'react';
import './RoundButton.css';

function RoundButton() {

    useEffect(() => {
        console.log(`RoundButton useEffect`);
    });

    return (
        <button className="round_button" title="New Egg"
            data-bs-toggle="modal"
            data-bs-target="#layEggModal"
            data-bs-username={""}
        ><span role="img" aria-label="egg">🥚</span></button>
    )
}

export default RoundButton