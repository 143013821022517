import { useContext, useRef } from 'react'
import { AuthContext } from '../context/Auth.context.js';

function AccountSetData({ email }) {
    const { state, setLoginNick } = useContext(AuthContext);

    const inputNickRef = useRef(null);

    const editUserData = async (event) => {
        //Prevent page reload
        event.preventDefault();

        const username = inputNickRef.current.value.trim();
        const userData = {
            username: username
        }
        await state.user.functions.editUserData(userData); //TODO unique
        await state.user.refreshCustomData();
        setLoginNick(state.user.customData.nick);
    }

    return (
        <>
            <div className="mb-3">
                <div>Email: <span id="email-span">{email}</span></div>
            </div>
            <form onSubmit={editUserData}>
                <div className="mb-3">
                    <label htmlFor="username-text" className="col-form-label">Username:</label>
                    <input ref={inputNickRef} type="text" className="form-control" id="username-text" maxLength="64" minLength="3" required />
                </div>
                <button type="submit" className="btn btn-primary">Save</button>
            </form>
        </>
    )
}

export default AccountSetData