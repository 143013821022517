import { useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../context/Auth.context.js';
import buildModal from '../modules/AlertModal.js';
import './NewEggModal.css'

function NewEggModal() {
    const { state } = useContext(AuthContext);
    const ref = useRef(null);

    useEffect(() => {
        const handleShow = event => {
            // Button that triggered the modal
            const button = event.relatedTarget;
            // Extract info from data-bs-* attributes
            const username = button.getAttribute('data-bs-username');
            // Update the modal's content.
            const modalUsernameInput = ref.current.querySelector('#to-name');
            modalUsernameInput.value = username;
        };

        const element = ref.current;
        element.addEventListener('show.bs.modal', handleShow);

        return () => {
            element.removeEventListener('show.bs.modal', handleShow);
        };
    }, []);

    const layAnEgg = async (event) => {
        const eggForm = event.currentTarget;

        //Prevent page reload
        event.preventDefault();

        const message = eggForm.messageText.value.trim();
        const to = eggForm.toName.value.trim();
        const hatchDate = eggForm.hatchDate.value.trim();
        const hatchTime = eggForm.hatchTime.value.trim();
        let hatchDateTime = null;
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log(tz);
        hatchDateTime = hatchDate + "T" + hatchTime + ":00"; //ISO8601
        hatchDateTime = Date.parse(hatchDateTime); //Local

        const mex = {
            "message": message,
            "expDate": hatchDateTime,
            "sentBy": state.nick, //TODO app.currentUser.id,
            "to": to, //ID, nick, email, ???
            "tz": tz
        };

        try {
            const res = await state.user.functions.insertMex(mex);
            buildModal('<i className="fa-solid fa-egg"></i> The egg has been laid!<br>').show();
        } catch (err) {
            buildModal('ERROR!').show();
        } finally {
            //Simulate close button click //TODO data-bs-dismiss="modal"
            document.getElementById('layEggCloseBtn').click();
        }
    }

    return (
        <div className="modal fade" id="layEggModal" ref={ref} tabIndex="-1" aria-labelledby="layEggModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="layEggModalLabel"><i className="fa-solid fa-egg"></i> Lay an Egg</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form onSubmit={layAnEgg}>
                        <div className="modal-body">
                            <div className="mb-3">
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">@</span>
                                    <input name="toName" id="to-name" list="my-contacts" autoComplete="off" className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" minLength="3" required />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="message-text" className="col-form-label">Message:</label>
                                <textarea className="form-control" name="messageText" id="message-text" minLength="1" required></textarea>
                            </div>
                            <div className="mb-3">
                                <label className="col-form-label">Hatch date:</label>
                                <input type="date" className="form-control" id="hatch-date" name="hatchDate"
                                    autoComplete="off" required />
                                <label className="col-form-label">Hatch time:</label>
                                <input type="time" className="form-control" id="hatch-time" name="hatchTime"
                                    autoComplete="off" required />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="layEggCloseBtn" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary">Ok</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default NewEggModal