import React, { useContext, useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
//import logo from './logo.svg';
import './App.css';
import { AuthContext } from './context/Auth.context.js';
import Login from './components/Login';
import Nest from './components/Nest';
import Header from './components/Header';
import Footer from './components/Footer';
import AccountSetData from './components/AccountSetData';
import AccountModal from './components/AccountModal';
import HeaderButtons from './components/HeaderButtons';
import RoundButton from './components/RoundButton';
import NewEggModal from './components/NewEggModal';
import PACKAGE_JSON from '../package.json';
import {askNotificationPermission} from './modules/Notifications.js';


function App() {
  const { state } = useContext(AuthContext);
  const [isSmallWindow, setIsSmallWindow] = useState(false)

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 1000) {
      setIsSmallWindow(true)
    } else {
      setIsSmallWindow(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    askNotificationPermission();
  })

  return (
    <div id="app-root" className="col-lg-8 mx-auto m-1 p-1 py-md-3">
      <div className="App-header">
        <Header />
        {state.isLoggedIn && <HeaderButtons isSmallWindow={isSmallWindow} isMobile={isMobile} />}
        {(state.isLoggedIn && (isMobile || isSmallWindow) ) && <RoundButton />}
      </div>
      <div className="App-body p-1">
        {
          state.isLoggedIn
            ? (
              state.nick.length <= 0
                ? <AccountSetData email={state.user.customData.data.email} />
                : (
                  <>
                    <NewEggModal />
                    <Nest />
                    <AccountModal userName={state.nick} email={state.user.customData.data.email} />
                  </>
                )
            )
            : <Login />
        }
      </div>
      <div className="App-footer">
        <Footer year={"2022"} version={PACKAGE_JSON.version} />
      </div>
    </div>
  );
}

export default App;