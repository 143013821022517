//https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API

function checkNotificationPromise() {
    try {
        Notification.requestPermission().then();
    } catch (e) {
        return false;
    }

    return true;
}

export const askNotificationPermission = () => {
    // function to actually ask the permissions
    function handlePermission(permission) {
        //NOP
    }

    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
        console.log("This browser does not support notifications.");
    } else if (checkNotificationPromise()) {
        Notification.requestPermission().then((permission) => {
            handlePermission(permission);
        });
    } else {
        Notification.requestPermission((permission) => {
            handlePermission(permission);
        });
    }
}

// Create and show the notification
export function createNotification(text) {
    const img = './logo192.png';
    //const text = `${title}`;
    const notification = new Notification("EggMex", { body: text, icon: img });
}