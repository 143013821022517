import * as Realm from "realm-web";
import React, { useEffect } from 'react';
import { useSetState } from 'react-use';
import {createNotification} from '../modules/Notifications.js';
const ATLAS_SERVICE = 'mongodb-atlas';

export const AuthContext = React.createContext(null);

const initialState = {
    isLoggedIn: false,
    isLoginPending: false,
    loginError: null,
    user: null,
    nick: "",
    newEggs: false
}

let breakAsyncIterator = false; // Later used to exit async iterator
let usersCollIter = null;

export const ContextProvider = props => {
    const myApp = props.myApp; //TODO
    let startState = initialState;
    //per mantenere lo stato autenticazione fra refresh della pagina
    if (myApp?.currentUser?.isLoggedIn) {
        let newState = {
            ...initialState,
            isLoggedIn: true,
            user: myApp.currentUser,
            nick: myApp.currentUser.customData.nick,
            newEggs: myApp.currentUser.customData.newEggs
        };
        startState = newState;
    }

    const [state, setState] = useSetState(startState);

    const setLoginPending = (isLoginPending) => setState({ isLoginPending });
    const setLoginSuccess = (isLoggedIn) => setState({ isLoggedIn });
    const setLoginError = (loginError) => setState({ loginError });
    const setLoginUser = (user) => setState({ user });
    const setLoginNick = (nick) => setState({ nick });
    const setNewEggs = (newEggs) => setState({ newEggs });

    const checkEmail = (email) => {
        let e = email.toLowerCase();
        e = e.trim();
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //https://emailregex.com/
        if (emailRegex.test(e))
            return e;
        return "";
    }

    const checkPassword = (password) => {
        let p = password.trim();
        return p;
    }

    const login = async (email, password) => {
        setLoginPending(true);
        setLoginSuccess(false);
        setLoginError(null);

        const e = checkEmail(email);
        const p = checkPassword(password);
        const credentials = Realm.Credentials.emailPassword(e, p);
        let user = null;
        breakAsyncIterator = false;
        try {
            // Authenticate the user
            user = await myApp.logIn(credentials);
            setLoginPending(false);
            // `App.currentUser` updates to match the logged in user
            console.assert(user.id === myApp.currentUser.id);
            //console.log(user.customData.data.email) //TODO
            setLoginSuccess(user.isLoggedIn);
            if (user.isLoggedIn) {
                setLoginUser(user);
                setLoginNick(user.customData.nick);
            }
        } catch (err) {
            console.error("Failed to log in", err);
            setLoginError(err);
            return;
        }
        //
        watchUserUpdates(user.customData.nick);//TODO
    }

    const logout = async () => {
        try {
            await myApp.currentUser.logOut();
        } catch (err) {
            console.error("Failed to log out", err);
        } finally {
            breakAsyncIterator = true;
            setLoginPending(false);
            setLoginError(null);
            if (myApp.currentUser) {
                setLoginSuccess(myApp.currentUser.isLoggedIn);
            } else {
                setLoginSuccess(false);
                setLoginUser(null);
                setLoginNick("");
            }
        }
    }

    const watchUserUpdates = async (username) => {
        const mongo = myApp.currentUser.mongoClient(ATLAS_SERVICE);
        if (usersCollIter) usersCollIter.return(); //chiude/termina/pulisce se c'era un iteratore
        usersCollIter = mongo.db("EggMex").collection("users").watch({
            filter: {
                operationType: "update",
                "fullDocument.nick": username,
            },
        });
        for await (const change of usersCollIter) {
            const { documentKey, fullDocument } = change;
            console.log(`Check for new messages: ${documentKey}`, fullDocument);
            await myApp.currentUser.refreshCustomData().then((value) => {
                if (fullDocument.newEggs) { //TODO //if (state.newEggs != fullDocument.newEggs) {
                    setNewEggs(fullDocument.newEggs);
                    createNotification("New Egg!");
                }
            });
            if (breakAsyncIterator) break; // Exit async iterator
        }
    }

    useEffect(() => {
        console.log(`AuthContext useEffect`);
        if (state.isLoggedIn) {
            watchUserUpdates(state.user.customData.nick);//TODO
        } else {
            if (usersCollIter) usersCollIter.return(); //chiude/termina/pulisce se c'era un iteratore
        }
    });

    return (
        <AuthContext.Provider
            value={{
                state,
                login,
                logout,
                setLoginNick,
                setNewEggs
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};