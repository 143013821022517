import React, { useContext } from 'react';
import { useSetState } from 'react-use';
import { AuthContext } from '../context/Auth.context.js';

const initialState = {
    email: '',
    password: ''
}

function Login() {
    const { state: ContextState, login } = useContext(AuthContext);
    const {
        isLoginPending,
        isLoggedIn,
        loginError
    } = ContextState;
    const [state, setState] = useSetState(initialState);

    const handleClick = async (event) => {
        event.preventDefault();
        const { email, password } = state;
        await login(email, password);
        setState({
            email: '',
            password: ''
        });
    };

    return (
        <div className="form">
            <form onSubmit={handleClick}>
                <div className="form-floating">
                    <input
                        type="email"
                        name="uname"
                        onChange={e => setState({ email: e.target.value })}
                        className="form-control"
                        placeholder="name@example.com"
                        minLength="6"
                        required />
                    <label htmlFor="emailLogin">Email address</label>
                </div>
                <div className="form-floating mt-1">
                    <input
                        type="password"
                        name="pass"
                        onChange={e => setState({ password: e.target.value })}
                        className="form-control"
                        placeholder="Password"
                        minLength="8"
                        required />
                    <label htmlFor="passwordLogin">Password</label>
                </div>
                <button className="w-100 btn btn-lg btn-warning mt-1" type="submit">Login</button>
            </form>
            {isLoginPending && <div>Please wait...</div>}
            {isLoggedIn && <div>Success.</div>}
            {loginError && <div>{loginError.message}</div>}
        </div>
    )
}

export default Login