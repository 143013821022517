import React, { useContext, useState } from "react";
import { AuthContext } from '../context/Auth.context.js';
import buildModal from '../modules/AlertModal.js';

const Autocomplete = () => {
    const { state: ContextState } = useContext(AuthContext);
    const [isValidInput, setIsValidInput] = useState(false);
    const [filtered, setFiltered] = useState([]);
    const [input, setInput] = useState("");

    const onChange = e => {
        const input = e.currentTarget.value.toLowerCase();
        setInput(input);

        if (input.length >= 3)
            ContextState.user.functions.usernamesAuto({ term: input })
                .then((data => {
                    setFiltered(data
                        .filter(user => user.nick.toLowerCase() !== ContextState.nick.toLowerCase())
                        .map(user => user.nick));
                    const selected = filtered.find(user => user.toLowerCase() === input);
                    if (selected)
                        setIsValidInput(true)
                    else
                        setIsValidInput(false);
                })
                )
        else
            setFiltered([]);
    };
    const renderAutocomplete = () => {
        if (input) {
            const autoList = filtered.map((suggestion, index) => {
                return (
                    <option key={suggestion} value={suggestion} >
                        {suggestion}
                    </option>
                );
            });
            return autoList;
        }
        return <></>;
    }
    const addContact = e => {
        console.log("addClick " + isValidInput);
        if (isValidInput && input.length > 0) {
            const res = ContextState.user.functions.addContact(input)
                .then(res => buildModal(res.toString()).show())
        } else {
            buildModal(`Invalid username`).show();
        }
    }

    return (
        <div className="mt-2 mx-3">
            <label htmlFor="searchNewUser" className="form-label"><i className="fa-solid fa-user-plus"></i> Add new contact:</label>
            <div className="input-group mb-3">
                <input autoComplete="off" id="searchNewUser" type="text" className="form-control" placeholder="Type to search..." aria-label="Search for username" aria-describedby="button-addon2"
                    list="filtered-contacts"
                    onChange={onChange}
                    value={input}
                />
                <datalist id="filtered-contacts">
                    {renderAutocomplete()}
                </datalist>
                <button className="btn btn-outline-warning" title="Add" type="button"
                    onClick={addContact}
                    disabled={!isValidInput}
                >
                    +
                </button>
            </div>
        </div>
    );
}

export default Autocomplete;