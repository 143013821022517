import { useContext, useState } from 'react';
import { AuthContext } from '../context/Auth.context.js';
import buildModal from '../modules/AlertModal.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';

function EggListItem({ id, from = null, to = null, expDate, expDateLong, insDate }) {
    const { state } = useContext(AuthContext);
    const [isPending, setIsPending] = useState(false)

    const handleClick = (event) => {
        const eggId = event.currentTarget.id;
        const nowDate = moment();
        const endM = moment(expDateLong);
        const diff = endM.diff(nowDate, 'seconds');
        console.log(`${diff} ${nowDate.toString()} ${endM.toString()}`);
        if (diff <= 0) {
            setIsPending(true);
            const req = {
                mex_id: eggId
            }
            state.user.functions.getMessage(req).then((doc) => {
                setIsPending(false);
                buildModal(`${doc.message}`).show();
            });
        } else {
            buildModal('<i class="fa-solid fa-egg"></i> Be patient ...<br>').show();
        }
    };

    return (
        <li id={id} onClick={handleClick}
            className="list-group-item d-flex justify-content-between align-items-start"
            style={{ cursor: "pointer" }}
        >
            <div className="ms-2 me-auto">
                <div className="fw-bold">{from ? from : to}</div>
                <span role="img" aria-label="egg">🥚</span>{insDate}
                {isPending && <FontAwesomeIcon className="px-2" icon={faCircleNotch} spin/>}
            </div>
            <span className="badge bg-primary "><span role="img" aria-label="hatching-chick">🐣</span> {expDate}</span>
        </li>
    )
}

export default EggListItem