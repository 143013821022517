function MenuElement({ text, modal, action, href = "#!" }) {
    const handleClick = async (event) => {
        event.preventDefault();
        await action();
    };

    if (modal)
        return (
            <a className="nav-link" href={href} data-bs-toggle="modal" data-bs-target={modal}>{text}</a>
        )
    if (action)
        return (
            <a className="nav-link" href={href} onClick={handleClick}>{text}</a>
        )
}

export default MenuElement