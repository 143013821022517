import NewEggBtn from './NewEggBtn.js';

function ContactListItem({ username }) {

    const handleClick = (event) => {
        const action = event.currentTarget.name;
        if (action === "send") {
            console.log(`Click SEND ${username}`);
        }
        else if (action === "delete") {
            console.log(`Click DELETE ${username}`);
        }
        //TODO
    };

    return (
        /** TODO onClick */
        <li className="list-group-item d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto">
                {username}
            </div>
            <div className="btn-group" role="group" aria-label="Buttons">
                <NewEggBtn username={username} className="btn btn-outline-warning" title="Lay an egg" />
                <button type="button" name="delete" onClick={handleClick} className="btn btn-outline-danger" title="Delete"><span role="img" aria-label="Delete">🗑</span></button>
            </div>
        </li>
    )
}

export default ContactListItem