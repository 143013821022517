import React, { useState } from "react";
import ContactListItem from './ContactListItem.js';

//TODO
function clone(o) {
    return JSON.parse(JSON.stringify(o));
}

const ContactList = (props) => {
    const [contacts, setContacts] = useState(props.contacts);
    const [searchData, setSearchData] = useState(clone(props.contacts));
    //const [input, setInput] = useState("");

    const contactsList = searchData.map((contact) => {
        return (
            <ContactListItem key={contact} username={contact} />
        )
    });

    const onChange = e => {
        const input = e.currentTarget.value.toLowerCase();
        //setInput(input);

        if (input.length > 0) {
            setSearchData(contacts.filter((contact) => {
                return contact.toLowerCase().indexOf(input) > -1;
            }));
        } else {
            setSearchData(clone(contacts));
        }
    };

    return (
        <>
            <div className="mx-3">
                <label htmlFor="searchUser" className="form-label"><i className="fa-solid fa-magnifying-glass"></i> Search:</label>
                <input autoComplete="off" id="searchUser" type="text" className="form-control" placeholder="Type to search..." aria-label="Search for username" aria-describedby="button-addon2"
                    onChange={onChange}
                />
            </div>
            <ul className="list-group list-group-flush mt-1">
                {contactsList}
            </ul>
        </>
    );
}

export default ContactList;