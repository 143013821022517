import React, { useContext } from 'react';
import { AuthContext } from '../context/Auth.context.js';
import './HeaderButtons.css'

function HeaderButtons({ isMobile, isSmallWindow }) {
    const { state: ContextState, setNewEggs } = useContext(AuthContext);

    //On open Received
    const handleReceivedClick = (event) => {
        if (ContextState.user.customData.newEggs)
            setTimeout(
                async () => {
                    setNewEggs(false);
                    await ContextState.user.functions.setAllRead();
                    console.log("click");
                },
                30000
            )
    }

    return (
        <ul className="nav nav-pills mt-2" id="myTab" role="tablist">
            <li className="nav-item m-1" role="presentation">
                <button className="nav-link  nav-link-sm active position-relative" id="rxeggs-tab" data-bs-toggle="tab" data-bs-target="#rxeggs" type="button" role="tab" aria-controls="rxeggs" aria-selected="true"
                    onClick={handleReceivedClick}
                >
                    Received Eggs
                    {ContextState.user.customData.newEggs && (
                        <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                            <span className="visually-hidden">New Eggs!</span>
                        </span>
                    )}
                </button>
            </li>
            <li className="nav-item m-1" role="presentation">
                <button className="nav-link nav-link-sm" id="txeggs-tab" data-bs-toggle="tab" data-bs-target="#txeggs" type="button" role="tab" aria-controls="txeggs" aria-selected="false">
                    Sent Eggs
                </button>
            </li>
            <li className="nav-item m-1" role="presentation">
                <button className="nav-link nav-link-sm" id="contacts-tab" data-bs-toggle="tab" data-bs-target="#contacts" type="button" role="tab" aria-controls="contacts" aria-selected="false">
                    Contacts
                </button>
            </li>
            { (!isMobile && !isSmallWindow) &&
                <li className="nav-item m-1" role="presentation">
                    <button className="nav-link nav-link-sm nav-link-newegg" id="newegg-tab" type="button" role="tab" aria-controls="newegg" aria-selected="false"
                        data-bs-toggle="modal"
                        data-bs-target="#layEggModal"
                        data-bs-username={""}
                    >
                        New Egg
                    </button>
                </li>
            }
        </ul>
    )
}

export default HeaderButtons;