import { useEffect } from 'react';
import './Footer.css';

function Footer({ year, version }) {

    useEffect(() => {
        console.log(`Footer useEffect`);
    });

    return (
        <footer className="App-footer">
            <p className="mt-3 mb-3 text-muted text-center">&copy; {year} (v{version})</p>
        </footer>
    )
}

export default Footer